
function mySearch(text, classname) {
  // ".display tr"
  var value = text.toLowerCase();
  $("." + classname).filter(function () {
    $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
  });
}

$(document).ready(function () {

  $("#ClientContainer").hide();
  $("#EngagementContainer").hide();
  $("#DocumentContainer").hide();

  $(".dateContainerjs").click(function () {
    $("#DateContainer").show();
    $("#ClientContainer").hide();
    $("#EngagementContainer").hide();
    $("#DocumentContainer").hide();
  });

  $(".ClientContainerjs").click(function () {
    $("#DateContainer").hide();
    $("#ClientContainer").show();
    $("#EngagementContainer").hide();
    $("#DocumentContainer").hide();
  });

  $(".EngagementContainerjs").click(function () {
    $("#DateContainer").hide();
    $("#ClientContainer").hide();
    $("#EngagementContainer").show();
    $("#DocumentContainer").hide();
  });

  $(".DocumentContainerjs").click(function () {
    $("#DateContainer").hide();
    $("#ClientContainer").hide();
    $("#EngagementContainer").hide();
    $("#DocumentContainer").show();
  });

  /* Code to show Client Details Popup */

});

function toggleIcon() {
  if ($("#slide").attr("src") === "../../../../assets/images/listview/slide_up.png") {
    $("#slide").attr("src", "../../../../assets/images/listview/slide_down.png");
  } else {
    $("#slide").attr("src", "../../../../assets/images/listview/slide_up.png");
  }
}

function collapseOrExpandCardView() {
  if ($("#slide").attr("src") === "../../../../assets/images/listview/slide_up.png") {
    $(".card").removeClass("card-small");
  } else {
    $(".card").addClass("card-small");

  }
}

function ToggleListAndCardView(e) {
  if ($(e).hasClass("ShowListViewjs")) {
    $(".CardViewImg").attr("src", "../../../../assets/images/listview/card-view_un-selected.png");
    $(".ListViewImg").attr("src", "../../../../assets/images/listview/list-view_selected.png");
    $(".ListViewContainer").show();
    $('.msaListViewContainer').show();
    $(".cardViewContainer").hide();
    $(".CardViewFilters").hide();
  }
  else if ($(e).hasClass("ShowCardViewjs")) {
    $(".CardViewImg").attr("src", "../../../../assets/images/listview/card-view_selected.png");
    $(".ListViewImg").attr("src", "../../../../assets/images/listview/list-view_un-selected.png");
    $(".cardViewContainer").show();
    $(".ListViewContainer").hide();
    $('.msaListViewContainer').hide();
    $(".CardViewFilters").show();
    $(".CardViewFilters").css('display', 'inherit');
    /* Code to show Relationship Partener Popup */
  }
  else {
    $(".CardViewImg").attr("src", "../../../../assets/images/listview/card-view_un-selected.png");
    $(".ListViewImg").attr("src", "../../../../assets/images/listview/list-view_selected.png");
    $(".cardViewContainer").hide();
    $(".ListViewContainer").hide();
    $('.msaListViewContainer').hide();
    $(".CardViewFilters").hide();

  }
}
//ClientNameFilterjs

function AddRemoveCheckBox(CheckAll, eachCheckBox, flag)
{
  $("." + CheckAll ).prop('checked', flag);
  $('.' + eachCheckBox ).each(function () {
    $(this).prop('checked', flag);
  });
}

function filterFunctionList(filterSearchBox, DropdowntoFilter,CheckAll,eachCheckBox) {

  var filterSearchBoxVal = $("#" + filterSearchBox ).val();
  if (filterSearchBoxVal != '')
  {
    AddRemoveCheckBox(CheckAll,eachCheckBox,false);
  }
  else {
    AddRemoveCheckBox(CheckAll,eachCheckBox,true);
  }

  var input, filter, ul, li, a, i, txtValue;
    input = document.getElementById(filterSearchBox);
    filter = input.value.toUpperCase();
    ul = document.getElementById(DropdowntoFilter);
    li = ul.getElementsByTagName("li");
    for (i = 1; i < li.length; i++) {
      a = li[i].getElementsByTagName("a")[0];
      if (a != undefined) {
        txtValue = a.textContent || a.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          li[i].style.display = "";
        } else {
          li[i].style.display = "none";
        }
      }
    }
}

// listview function
function applyListView(fnCallBack) {
  $(".searchbox").on("keyup", function () {
    mySearch(this.value, "searchDatajs");
  });

  $(".expand-icon").on("click", function () {
    toggleIcon();
    collapseOrExpandCardView();
  });

  fnCallBack();
}

function CloseFilter(e) {
  $(e).parents('.dropdown').find('button.dropdown-toggle').dropdown('toggle');
}

function ShowAllClients() {
  var names = $(".ClientNamejs").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".ClientNameFilterjs").append("<li role='presentation'><input class='CheckClient' type='checkbox' checked data-attribute='ClientName' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".ClientNameFilterjs").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='btn PrimaryButton' value='Apply' onClick=FilterClientData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}

function ShowAllServiceLine() {
  var names = $(".ServiceLinejs").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".ServiceLineFilterjs").append("<li><input class='CheckServiceLine' type='checkbox' checked data-attribute='ServiceLine' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".ServiceLineFilterjs").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='btn PrimaryButton' value='Apply' onClick=FilterClientData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}

function ShowAllClientPrograms() {
  var names = $(".ClientProgramjs").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".ClientProgramsFilterjs").append("<li><input class='CheckClientProgram' checked type='checkbox' data-attribute='ClientProgram' data-value='" + obj + "'>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".ClientProgramsFilterjs").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='btn PrimaryButton' value='Apply' onClick=FilterClientData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllCompanies() {
  var names = $(".Companyjs").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".PublicPrivateFilterjs").append("<li><input class='CheckPublicPrivate' type='checkbox' checked data-attribute='PublicPrivate' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".PublicPrivateFilterjs").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='btn PrimaryButton' value='Apply' onClick=FilterClientData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}


function getUnique(array) {
  var uniqueArray = [];

  // Loop through array values
  for (var i = 0; i < array.length; i++) {
    if (uniqueArray.indexOf(array[i]) === -1) {
      uniqueArray.push(array[i]);
    }
  }
  return uniqueArray;
}
//Filtering on card view code
function CheckUncheckAll(chkAll, checkbox) {
  $('.' + chkAll).on('change', function () {
    $('.' + checkbox).prop('checked', $(this).prop("checked"));
  });
  //deselect "checked all", if one of the listed checkbox product is unchecked amd select "checked all" if all of the listed checkbox product is checked
  $('.' + checkbox).change(function () { //".checkbox" change
    if ($('.' + checkbox + ':checked').length == $('.' + checkbox).length) {
      $('.' + chkAll).prop('checked', true);
    } else {
      $('.' + chkAll).prop('checked', false);
    }
  });
}

function FilterClientData() {
  var list = [];

  $('.CheckClient,.CheckServiceLine,.CheckClientProgram,.CheckPublicPrivate,.CheckAllClients,.checkAllServiceLine,.checkAllClientProgram,.checkAllPublicPrivate').each(function () {
    if ($(this).is(':checked')) {
      list.push({
        "attribute": $(this).attr("data-attribute"),
        "value": $(this).attr("data-value")
      });
    }
  });

  $(".FilterDatajs").filter(function () {
    $("#NoDataMessage").addClass('hide');
    var result = false;
    var $this = $(this);
    result = filterText('ClientNameForFilter', 'ClientName', $this, list);

    var serviceValuelist = [];

    $(this).find('.ServiceLineForFilter').each(function () {
      serviceValuelist.push($(this).text());
    });

    var serviceList = $(list).filter(function (e, obj) {
      return obj.attribute == 'ServiceLine' && serviceValuelist.indexOf(obj.value) > -1;
    });


    result = result && (serviceList.length > 0 ? true : false);

    result = result && filterText('ClientProgramForFilter', 'ClientProgram', $this, list);
    result = result && filterText('PublicPrivateForFilter', 'PublicPrivate', $this, list);


    if (result == false) {
      $("#NoDataMessage").removeClass('hide');
    }



    $(this).toggle(result);
    var originalFilterLength = $(".FilterDatajs").length;
    var hiddenFilterdata = $('.FilterDatajs').filter(function () {
      return $(this).css('display') == 'none';
    }).length;

    if (originalFilterLength == hiddenFilterdata) {
      $("#NoDataMessage").removeClass('hide');
    }
    else
    { $("#NoDataMessage").addClass('hide');}
  });
}

function filterText(clsForFilter, attrName, obj, list) {
  var result = false;
  var value = $(obj).find('.' + clsForFilter).text();

  var newList = $(list).filter(function (e, object) {
    return (object.attribute == ('chkAll-' + attrName)) ||
      (object.attribute == attrName && object.value == value);
  });

  if (newList.length > 0) {
    result = true;
  }

  return result;
}

function setValueToRPContactCard(rpName) {
  $('.headingFont').html(rpName);
}

//ClientNameReviewFilterjs
function filterFunctionReview(filterSearchBox, DropdowntoFilter) {
  var input, filter, ul, li, a, i, txtValue;
  input = document.getElementById(filterSearchBox);
  filter = input.value.toUpperCase();
  ul = document.getElementById(DropdowntoFilter);
  li = ul.getElementsByTagName("li");
  for (i = 1; i < li.length; i++) {
    a = li[i].getElementsByTagName("a")[0];
    txtValue = a.textContent || a.innerText;
    if (a != undefined){
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  }
}

function CloseFilterReview(e) {
  $(e).parents('.dropdown').find('button.dropdown-toggle').dropdown('toggle');
}

function ShowAllClientNameReview() {
  var names = $(".ClientNamejsReview").map(function () { return $(this).text(); });

  var distinctNames = getUniqueArrayReview(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".ClientNameFilterjsReview").append("<li><input class='CheckClientReview' type='checkbox' checked data-attribute='ClientName' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".ClientNameFilterjsReview").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='btn PrimaryButton' value='Apply' onClick=filterReviewData()> <input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilterReview(this)></div>");
}


function ShowAllEngNameReview() {
  var names = $(".EngNamejsForFilterReview").map(function () { return $(this).text(); });

  var distinctNames = getUniqueArrayReview(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".EngNameFilterjsReview").append("<li><input class='CheckEngNameReview' type='checkbox' checked data-attribute='Engagement' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".EngNameFilterjsReview").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='btn PrimaryButton' value='Apply' onClick=filterReviewData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilterReview(this)></div>");
}


function ShowAllSolutionCodeReview() {
  var names = $(".SolutionCodejsForFilterReview").map(function () { return $(this).text(); });

  var distinctNames = getUniqueArrayReview(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".SolutionCodeFilterjsReview").append("<li><input class='CheckSolutionCodeReview' type='checkbox' checked data-attribute='SolutionCode' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".SolutionCodeFilterjsReview").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='btn PrimaryButton' value='Apply' onClick=filterReviewData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilterReview(this)></div>");
}

function ShowAllStatusReview() {
  var names = $(".StatusjsForFilterReview").map(function () { return $(this).text(); });

  var distinctNames = getUniqueArrayReview(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".StatusFilterjsReview").append("<li><input class='CheckStatusReview' type='checkbox' checked data-attribute='Status' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".StatusFilterjsReview").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='btn PrimaryButton' value='Apply' onClick=filterReviewData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilterReview(this)></div>");
}

function ShowAllEPReview() {
  var names = $(".EPjsForFilterReview").map(function () { return $(this).text(); });

  var distinctNames = getUniqueArrayReview(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".EPFilterjsReview").append("<li role='presentation'><input class='CheckEPReview' type='checkbox' checked data-attribute='EP' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".EPFilterjsReview").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='btn PrimaryButton' value='Apply' onClick=filterReviewData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilterReview(this)></div>");
}

function getUniqueArrayReview(array) {
  var uniqueArray = [];

  // Loop through array values
  for (var i = 0; i < array.length; i++) {
    if (uniqueArray.indexOf(array[i]) === -1) {
      uniqueArray.push(array[i]);
    }
  }
  return uniqueArray;
}


function filterReviewData() {
  var list = [];

  $('.CheckClientReview,.CheckEngNameReview,.CheckSolutionCodeReview,.CheckStatusReview,.CheckEPReview,.CheckAllClientNameReview,.CheckAllEngNameReview,.CheckAllSolutionCodeReview,.CheckAllStatusReview,.CheckAllEPReview').each(function () {
    if ($(this).is(':checked')) {
      list.push({
        "attribute": $(this).attr("data-attribute"),
        "value": $(this).attr("data-value")
      });
    }
  });

  $(".FilterReviewDatajs").filter(function () {

    $("#NoDataMessage").addClass('hide');
    var result = false;
    var $this = $(this);

    result = filterReviewText('ClientNamejsReview', 'ClientName', $this, list);
    result = result && filterReviewText('EngNamejsForFilterReview', 'Engagement', $this, list);
    result = result && filterReviewText('SolutionCodejsForFilterReview', 'SolutionCode', $this, list);
    result = result && filterReviewText('StatusjsForFilterReview', 'Status', $this, list);
    result = result && filterReviewText('EPjsForFilterReview', 'EP', $this, list);
    if (result == false) {
      $("#NoDataMessage").removeClass('hide');
    }

    $(this).toggle(result);

	  var originalFilterLength = $(".FilterReviewDatajs").length;
    var hiddenFilterdata = $('.FilterReviewDatajs').filter(function () {
      return $(this).css('display') == 'none';
    }).length;

    if (originalFilterLength == hiddenFilterdata) {
      $("#NoDataMessage").removeClass('hide');
    }
    else
    { $("#NoDataMessage").addClass('hide');}
  });

}

function filterReviewText(clsForFilter, attrName, obj, list) {
  var result = false;
  var value = $(obj).find('.' + clsForFilter).text();

  var newList = $(list).filter(function (e, eachObj) {
    return (eachObj.attribute == ('chkAll-' + attrName)) ||
      (eachObj.attribute == attrName && eachObj.value == value);
  });

  if (newList.length > 0) {
    result = true;
  }
  return result;
}

//Filtering on card view code
function CheckUncheckAllReview(chkAll, checkbox) {
  $('.' + chkAll).on('change', function () {
    $('.' + checkbox).prop('checked', $(this).prop("checked"));
  });
  //deselect "checked all", if one of the listed checkbox product is unchecked amd select "checked all" if all of the listed checkbox product is checked
  $('.' + checkbox).change(function () { //".checkbox" change
    if ($('.' + checkbox + ':checked').length == $('.' + checkbox).length) {
      $('.' + chkAll).prop('checked', true);
    } else {
      $('.' + chkAll).prop('checked', false);
    }
  });
}
