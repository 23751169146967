var msaWindowEN;
function loadMSAPreviewEN(fileURL) {
  msaWindowEN = window.open(fileURL, "_blank");
}
function openModalEN(link, buttonID) {
  var sessionGUID = uuidv4();
  // Set the link in the modal iframe
  document.getElementById('powerAppsFrame').src = link + sessionGUID;

  // Open the modal
  document.getElementById('myModal').style.display = 'block';
  checkSessionStatusEN(sessionGUID, buttonID);
}
function checkSessionStatusEN(sessionID, buttonID) {
  fetch(document.getElementById('idTextField').value, {
    method: "POST",
    body: JSON.stringify({
      sessionID: sessionID
    }),
    headers: {
      "Content-type": "application/json; charset=UTF-8"
    }
  })
    .then((response) => response.json())
    .then((json) => {
      console.log(json);
      determineSessionStatusEN(json.sessionStatus, json.sessionID, json, buttonID);
    }
    );
}
function determineSessionStatusEN(sessionStatus, sessionID, json, buttonID) {
  var html = "";
  if (sessionStatus.length == 0 || sessionStatus != "Closed") {
    //alert("The session is not closed!");
    if (document.getElementById('myModal').style.display == 'block') {
      setTimeout(function () {
        // Call your function here
        checkSessionStatusEN(sessionID, buttonID);
      }, 2000);
    }
  }
  else {
    document.getElementById('myModal').style.display = 'none';


  }
}
function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
    .replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
}