var msaWindow;
function loadMSAPreview(fileURL) {
    msaWindow = window.open(fileURL, "_blank");
}
function openModal(link, buttonID) {
    var sessionGUID = uuidv4();
    // Set the link in the modal iframe
    document.getElementById('powerAppsFrame').src = link + sessionGUID;

    // Open the modal
    document.getElementById('myModal').style.display = 'block';
    checkSessionStatus(sessionGUID, buttonID);
}
function checkSessionStatus(sessionID, buttonID) {
    fetch(document.getElementById('idTextField4').value, {
        method: "POST",
        body: JSON.stringify({
            sessionID: sessionID
        }),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    })
        .then((response) => response.json())
        .then((json) => {
            console.log(json);
            determineSessionStatus(json.sessionStatus, json.sessionID, json, buttonID);
        }
        );
}
function determineSessionStatus(sessionStatus, sessionID, json, buttonID) {
    var html = "";
    if (sessionStatus.length == 0 || sessionStatus != "Closed") {
        //alert("The session is not closed!");
        if (document.getElementById('myModal').style.display == 'block') {
            setTimeout(function () {
                // Call your function here
                checkSessionStatus(sessionID, buttonID);
            }, 2000);
        }
    }
    else {
        if (json.sessionFileName.length > 0) {
            if (json.source == 'iCertisAmendMSA') {
                html += "<div>";
                html += "<div><br></div>";
                html += "<div><strong>Update to selected MSA submitted by: </strong>" + json.sessionRequester + "</div>";
                html += "<div><strong>Update submitted on: </strong>" + json.sessionDate + "</div>";
                html += "</div>";
                document.getElementById(buttonID).innerHTML = html;
                document.getElementById('myModal').style.display = 'none';
            }
            //alert("The session is closed!");
            else {
                html += "<div>";
                html += "<div><strong>File uploaded: </strong>" + json.sessionFileName + "</div>";
                html += "<div><strong>Uploaded by: </strong>" + json.sessionRequester + "</div>";
                html += "<div><strong>Uploaded on: </strong>" + json.sessionDate + "</div>";
                html += "<div><br></div>";
                html += "</div>";
                document.getElementById(buttonID).innerHTML = html;
                document.getElementById('myModal').style.display = 'none';
            }
            if (json.source != 'iCertisAmendMSA') {
                var textField = document.getElementById('idTextField');
                // Get unique ID
                var uniqueid = document.getElementById('idTextField1').value;
                // Get the ID of the selected parent radio button
                var parentRadioId = document.querySelector('input[name="radioGroup"]:checked').id;
                var combinedId = uniqueid + '#' + parentRadioId + '#' + json.sysID + '#' + json.gaType + '#' + json.effectiveDate;

                textField.value = combinedId;
                return combinedId;
            }
        }
        else {
            document.getElementById('myModal').style.display = 'none';

            var radioButtons = document.getElementsByName('radioGroup');
            for (var i = 0; i < radioButtons.length; i++) {
                radioButtons[i].checked = false;
            }


            var radioButtons1 = document.getElementsByName('radioGroup1');
            for (var i = 0; i < radioButtons1.length; i++) {
                if (radioButtons1[i].checked) {
                    document.getElementById('existingGA').checked = true;
                    textField.value = combinedId;
                }
            }


            var textField = document.getElementById('idTextField');
            textField.value = "";
        }
    }
}
function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
        .replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
}

// Function to hide the element with the specified buttonID
function hideElement(buttonID) {
    var element = document.getElementById(buttonID);
    if (element) {
        element.innerHTML = '';
    }
}
function initEvents() {
    // Adding event listeners to all radio buttons
    var radioButtons = document.getElementsByName('radioGroup');
    for (var i = 0; i < radioButtons.length; i++) {
        radioButtons[i].addEventListener('change', function () {
            // Hide all result elements initially
            hideElement('uploadGAin');
            hideElement('createMSAin');
            hideElement('existingGAin');

            // Show the corresponding result element if its radio button is selected
            if (this.id === 'uploadGA') {
                document.getElementById('uploadGAin').style.display = 'block';
            } else if (this.id === 'createMSA') {
                document.getElementById('createMSAin').style.display = 'block';
            } else if (this.id === 'existingGA') {
                document.getElementById('existingGAin').style.display = 'block';
            }
            // Add else if conditions for other radio buttons as needed
        });
    }

    var radioButtons1 = document.getElementsByName('radioGroup1');
    for (var i = 0; i < radioButtons1.length; i++) {
        radioButtons1[i].addEventListener('change', function () {
            // Hide all result elements initially
            hideElement('uploadGAin');
            hideElement('createMSAin');
            hideElement('existingGAin');

            // Show the corresponding result element if its radio button is selected
            if (this.id === 'uploadGA') {
                document.getElementById('uploadGAin').style.display = 'block';
            } else if (this.id === 'createMSA') {
                document.getElementById('createMSAin').style.display = 'block';
            } else if (this.id === 'existingGA') {
                document.getElementById('existingGAin').style.display = 'block';
            }
            // Add else if conditions for other radio buttons as needed
        });
    }

    // Disable all update buttons by default
    document.querySelectorAll('.clmdesign').forEach(function (button) {
        button.disabled = true;
        button.style.background = '#F5F5F5';
    });



    // Event listener for radio buttons in radioGroup
    var radioButtons2 = document.getElementsByName('radioGroup');
    for (var i = 0; i < radioButtons2.length; i++) {
        radioButtons2[i].addEventListener('change', function () {
            document.querySelectorAll('.clmdesign').forEach(function (button) {
                button.disabled = true;
                button.style.background = '#F5F5F5';
            });
        });
    }

    // Event listener for radio buttons in radioGroup1
    var radioButtons3 = document.getElementsByName('radioGroup1');
    for (var i = 0; i < radioButtons3.length; i++) {
        radioButtons3[i].addEventListener('change', function () {
            var updateButton = this.closest('tr').querySelector('.clmdesign');
            if (updateButton) {
                enableUpdateButton(updateButton);
            } else {
                document.querySelectorAll('.clmdesign').forEach(function (button) {
                    button.disabled = true;
                    button.style.background = '#F5F5F5';
                });
            }
        });
    }

    // Check if a radio button in radioGroup1 is preselected
    var preselectedRadio = document.querySelector('input[name="radioGroup1"]:checked');
    if (preselectedRadio) {
        // If preselected radio button is found, enable its corresponding update button
        var updateButton = preselectedRadio.closest('tr').querySelector('.clmdesign');
        if (updateButton) {
            enableUpdateButton(updateButton);
        }
    }

    // Add event listener to handle radio button selection change for parent radio buttons
    document.querySelectorAll('[name="radioGroup"]').forEach(function (radioButton) {
        radioButton.addEventListener('change', handleRadioSelection);
    });
}

// Function to enable update button
function enableUpdateButton(button) {
    button.disabled = false;
    button.style.background = '#FFFFFF';
}

// Function to handle radio button selection
function handleRadioSelection() {
    // Get the parent radio buttons
    var parentRadioButtons = document.getElementsByName('radioGroup');

    // Get the first parent radio button (existingGA)
    var existingGARadioButton = document.getElementById('existingGA');

    // Get all HTML table radio buttons
    var tableRadioButtons = document.getElementsByName('radioGroup1');

    // Check if any table radio button has the checked attribute
    var checkedTableRadioButton = document.querySelector('input[name="radioGroup1"]:checked');
    if (checkedTableRadioButton) {
        // Select the corresponding parent radio button (existingGA)
        existingGARadioButton.checked = true;
    }

    // Add event listeners to HTML table radio buttons
    tableRadioButtons.forEach(function (radioButton) {
        radioButton.addEventListener('click', function () {
            // Select the first parent radio button (existingGA)
            existingGARadioButton.checked = true;
        });
    });

    // Add event listeners to other parent radio buttons
    parentRadioButtons.forEach(function (parentButton) {
        parentButton.addEventListener('click', function () {
            // Deselect all HTML table radio buttons
            tableRadioButtons.forEach(function (radioButton) {
                radioButton.checked = false;
            });
        });
    });
}



// Initial call to handle radio button selection
handleRadioSelection();



// Function to handle radio button selection
function gettextvalue() {
    // Get the text field
    var textField = document.getElementById('idTextField');

    // Get unique ID
    var uniqueid = document.getElementById('idTextField1').value;
    // Get the ID of the selected parent radio button
    var parentRadioId = document.querySelector('input[name="radioGroup"]:checked')?.id;

    // Get the ID of the selected table radio button
    var tableRadioId = document.querySelector('input[name="radioGroup1"]:checked');

    // Check if a table radio button is selected
    var tableRadioIdValue = tableRadioId ? tableRadioId.id : '00000000-0000-0000-000000000000';

    // Initialize variables for document type and effective date
    var documentType = '';
    var effectiveDate = '';

    // Determine document type and effective date based on parent radio button selection
    if (parentRadioId === 'existingGA') {
        // Assuming document type and effective date are available from the table row
        var selectedRow = document.querySelector('input[name="radioGroup1"]:checked').closest('tr');
        documentType = selectedRow.cells[3].innerText;
        effectiveDate = selectedRow.cells[5].innerText.trim() || '01/01/1900';
    } else if (parentRadioId === 'createHDEL') {
        // For radio button 4, set default values
        documentType = 'UEL';
        effectiveDate = '01/01/1900';
    } else if (parentRadioId === 'noMSAEL') {
        // For radio button 5, set default values
        documentType = 'NTD';
        effectiveDate = '01/01/1900';
    }

    // Combine the IDs and other values with '#' separator
    var combinedId = uniqueid + '#' + parentRadioId + '#' + tableRadioIdValue + '#' + documentType + '#' + effectiveDate;

    // Update the text field with the combined ID
    textField.value = combinedId;

    // Return the combined ID for further use if needed
    return combinedId;
}
function clmInitComponent() {

    // Add event listener to handle radio button selection change for parent radio buttons
    document.querySelectorAll('[name="radioGroup"]').forEach(function (radioButton) {
        radioButton.addEventListener('change', function () {
            // Call the function to update the text field
            var combinedId = gettextvalue();

            // Clear the console
            console.clear();

            // Here you can use the combinedId as needed
            console.log('Combined ID:', combinedId);
        });
    });

    // Add event listener to handle radio button selection change for table radio buttons
    document.querySelectorAll('[name="radioGroup1"]').forEach(function (radioButton) {
        radioButton.addEventListener('change', function () {
            // Call the function to update the text field
            var combinedId = gettextvalue();

            // Clear the console
            console.clear();

            // Here you can use the combinedId as needed
            console.log('Combined ID:', combinedId);
        });
    });

    // Call the function to handle initial selection and set the default value of the text field after a short delay
    setTimeout(function () {
        var defaultCombinedId = gettextvalue();
        document.getElementById('idTextField').value = defaultCombinedId;
    }, 10);

    // Adding event listeners to Radio2 and Radio3 to open modal on selection
    document.getElementById('uploadGA').addEventListener('change', function () {
        openModal(document.getElementById('idTextField2').value, 'uploadGAin');
    });

    document.getElementById('createMSA').addEventListener('change', function () {
        openModal(document.getElementById('idTextField3').value, 'createMSAin');
    });

    initEvents();
}
