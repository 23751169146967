
$(document).ready(function () {
  // var fnCallBack = function () {
  //   ShowAllClientNameForDoc();
  //   ShowAllEngagementsForDoc();
  //   ShowAllDocNamesForDoc();
  //   ShowAllDocTypesForDoc();
  //   ShowAllAuthorForDoc();
  //   ShowAllStatusForDoc();
  //   CheckUncheckAll('CheckAllClientsDoc', 'CheckClientForDoc');
  //   CheckUncheckAll('CheckAllEngDoc', 'CheckEngagementForDoc');
  //   CheckUncheckAll('CheckAllDocName', 'CheckDocNameForDoc');
  //   CheckUncheckAll('CheckAllDocType', 'CheckDocTypeForDoc');
  //   CheckUncheckAll('CheckAllAuthor', 'CheckAuthorForDoc');
  //   CheckUncheckAll('CheckAllStatusForDoc', 'CheckStatusForDoc');
  // }
  // applyListView(fnCallBack);
});

function ToggleListAndCardView(e) {
  if ($(e).hasClass("ShowListViewjs")) {
    $(".CardViewImg").attr("src", "../../../../assets/images/listview/card-view_un-selected.png");
    $(".ListViewImg").attr("src", "../../../../assets/images/listview/list-view_selected.png");
    $(".ListViewContainer").show();
    $(".cardViewContainer").hide();
    $(".CardViewFilters").hide();
  }
  else if ($(e).hasClass("ShowCardViewjs")) {
    $(".CardViewImg").attr("src", "../../../../assets/images/listview/card-view_selected.png");
    $(".ListViewImg").attr("src", "../../../../assets/images/listview/list-view_un-selected.png");
    $(".cardViewContainer").show();
    $(".ListViewContainer").hide();
    $(".CardViewFilters").show();
    $(".CardViewFilters").css('display', 'inherit');
  }
  else {
    $(".CardViewImg").attr("src", "../../../../assets/images/listview/card-view_un-selected.png");
    $(".ListViewImg").attr("src", "../../../../assets/images/listview/list-view_selected.png");
    $(".cardViewContainer").hide();
    $(".ListViewContainer").hide();
    $(".CardViewFilters").hide();

  }
}
function mySearch(text, classname) {
  // ".display tr"
  var value = text.toLowerCase();
  $("." + classname).filter(function () {
    $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
  });
}
function applyListView(fnCallBack) {
  $(".searchbox").on("keyup", function () {
    mySearch(this.value, "searchDatajs");
  });

  $(".expand-icon").on("click", function () {
    toggleIcon();
  });
    fnCallBack();
}

//Filtering on card view code
function CheckUncheckAll(chkAll, checkbox) {
  $('.' + chkAll).on('change', function () {
    $('.' + checkbox).prop('checked', $(this).prop("checked"));
  });
  //deselect "checked all", if one of the listed checkbox product is unchecked amd select "checked all" if all of the listed checkbox product is checked
  $('.' + checkbox).change(function () { //".checkbox" change
    if ($('.' + checkbox + ':checked').length == $('.' + checkbox).length) {
      $('.' + chkAll).prop('checked', true);
    } else {
      $('.' + chkAll).prop('checked', false);
    }
  });
}

//---------------ShowAllClientName------------------------
function ShowAllClientNameForDoc() {
  var names = $(".ClientNamejsForDoc").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".ClientNameFilterjsForDoc").append("<li><input class='CheckClientForDoc' type='checkbox' checked data-attribute='ClientName' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".ClientNameFilterjsForDoc").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterDocData()> <input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllClientNameForEL() {
  var names = $(".ClientNamejsForEL").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".ClientNameFilterjsForEL").append("<li><input class='CheckClientForEL' type='checkbox' checked data-attribute='ClientName' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".ClientNameFilterjsForEL").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterELData()> <input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllClientNameForSOW() {
  var names = $(".ClientNamejsForSOW").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".ClientNameFilterjsForSOW").append("<li><input class='CheckClientForSOW' type='checkbox' checked data-attribute='ClientName' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".ClientNameFilterjsForSOW").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterSOWData()> <input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllClientNameForMSA() {
  var names = $(".ClientNamejsForMSA").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".ClientNameFilterjsForMSA").append("<li><input class='CheckClientForMSA' type='checkbox' checked data-attribute='ClientName' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".ClientNameFilterjsForMSA").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterMSAData()> <input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllClientNameForMemo() {
  var names = $(".ClientNamejsForMemo").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".ClientNameFilterjsForMemo").append("<li><input class='CheckClientForMemo' type='checkbox' checked data-attribute='ClientName' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".ClientNameFilterjsForMemo").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterMemoData()> <input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllClientNameForCD() {
  var names = $(".ClientNamejsForCD").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".ClientNameFilterjsForCD").append("<li><input class='CheckClientForCD' type='checkbox' checked data-attribute='ClientName' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".ClientNameFilterjsForCD").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterCDData()> <input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllClientNameForCW() {
  var names = $(".ClientNamejsForCW").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".ClientNameFilterjsForCW").append("<li><input class='CheckClientForCW' type='checkbox' checked data-attribute='ClientName' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".ClientNameFilterjsForCW").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterCWData()> <input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllClientNameForAL() {
  var names = $(".ClientNamejsForAL").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".ClientNameFilterjsForAL").append("<li><input class='CheckClientForAL' type='checkbox' checked data-attribute='ClientName' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".ClientNameFilterjsForAL").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterALData()> <input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllClientNameForLD() {
  var names = $(".ClientNamejsForLD").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".ClientNameFilterjsForLD").append("<li><input class='CheckClientForLD' type='checkbox' checked data-attribute='ClientName' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".ClientNameFilterjsForLD").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterLDData()> <input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllClientNameForCL() {
  var names = $(".ClientNamejsForCL").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".ClientNameFilterjsForCL").append("<li><input class='CheckClientForCL' type='checkbox' checked data-attribute='ClientName' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".ClientNameFilterjsForCL").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterCLData()> <input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllClientNameForOD() {
  var names = $(".ClientNamejsForOD").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".ClientNameFilterjsForOD").append("<li><input class='CheckClientForOD' type='checkbox' checked data-attribute='ClientName' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".ClientNameFilterjsForOD").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterODData()> <input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
//---------------ShowAllEngagements------------------------

function ShowAllEngagementsForDoc() {
  var names = $(".EngagementjsForDoc").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".EngagementFilterjsForDoc").append("<li><input class='CheckEngagementForDoc' type='checkbox' checked data-attribute='Engagement' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".EngagementFilterjsForDoc").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterDocData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllEngagementsForEL() {
  var names = $(".EngagementjsForEL").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".EngagementFilterjsForEL").append("<li><input class='CheckEngagementForEL' type='checkbox' checked data-attribute='Engagement' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".EngagementFilterjsForEL").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterELData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllEngagementsForSOW() {
  var names = $(".EngagementjsForSOW").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".EngagementFilterjsForSOW").append("<li><input class='CheckEngagementForSOW' type='checkbox' checked data-attribute='Engagement' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".EngagementFilterjsForSOW").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterSOWData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllEngagementsForMSA() {
  var names = $(".EngagementjsForMSA").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".EngagementFilterjsForMSA").append("<li><input class='CheckEngagementForMSA' type='checkbox' checked data-attribute='Engagement' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".EngagementFilterjsForMSA").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterMSAData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllEngagementsForMemo() {
  var names = $(".EngagementjsForMemo").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".EngagementFilterjsForMemo").append("<li><input class='CheckEngagementForMemo' type='checkbox' checked data-attribute='Engagement' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".EngagementFilterjsForMemo").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterMemoData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllEngagementsForCD() {
  var names = $(".EngagementjsForCD").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".EngagementFilterjsForCD").append("<li><input class='CheckEngagementForCD' type='checkbox' checked data-attribute='Engagement' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".EngagementFilterjsForCD").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterCDData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllEngagementsForCW() {
  var names = $(".EngagementjsForCW").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".EngagementFilterjsForCW").append("<li><input class='CheckEngagementForCW' type='checkbox' checked data-attribute='Engagement' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".EngagementFilterjsForCW").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterCWData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllEngagementsForAL() {
  var names = $(".EngagementjsForAL").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".EngagementFilterjsForAL").append("<li><input class='CheckEngagementForAL' type='checkbox' checked data-attribute='Engagement' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".EngagementFilterjsForAL").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterALData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllEngagementsForLD() {
  var names = $(".EngagementjsForLD").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".EngagementFilterjsForLD").append("<li><input class='CheckEngagementForLD' type='checkbox' checked data-attribute='Engagement' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".EngagementFilterjsForLD").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterLDData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllEngagementsForCL() {
  var names = $(".EngagementjsForCL").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".EngagementFilterjsForCL").append("<li><input class='CheckEngagementForCL' type='checkbox' checked data-attribute='Engagement' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".EngagementFilterjsForCL").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterCLData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllEngagementsForOD() {
  var names = $(".EngagementjsForOD").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".EngagementFilterjsForOD").append("<li><input class='CheckEngagementForOD' type='checkbox' checked data-attribute='Engagement' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".EngagementFilterjsForOD").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterODData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}

//---------------ShowAllDocNames------------------------
function ShowAllDocNamesForDoc() {
  var names = $(".DocNamejsForDoc").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".DocNameFilterjsForDoc").append("<li><input class='CheckDocNameForDoc' type='checkbox' checked data-attribute='DocName' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".DocNameFilterjsForDoc").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterDocData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllDocNamesForEL() {
  var names = $(".DocNamejsForEL").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".DocNameFilterjsForEL").append("<li><input class='CheckDocNameForEL' type='checkbox' checked data-attribute='DocName' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".DocNameFilterjsForEL").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterELData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllDocNamesForSOW() {
  var names = $(".DocNamejsForSOW").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".DocNameFilterjsForSOW").append("<li><input class='CheckDocNameForSOW' type='checkbox' checked data-attribute='DocName' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".DocNameFilterjsForSOW").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterSOWData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllDocNamesForMSA() {
  var names = $(".DocNamejsForMSA").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".DocNameFilterjsForMSA").append("<li><input class='CheckDocNameForMSA' type='checkbox' checked data-attribute='DocName' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".DocNameFilterjsForMSA").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterMSAData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllDocNamesForMemo() {
  var names = $(".DocNamejsForMemo").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".DocNameFilterjsForMemo").append("<li><input class='CheckDocNameForMemo' type='checkbox' checked data-attribute='DocName' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".DocNameFilterjsForMemo").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterMemoData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllDocNamesForCD() {
  var names = $(".DocNamejsForCD").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".DocNameFilterjsForCD").append("<li><input class='CheckDocNameForCD' type='checkbox' checked data-attribute='DocName' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".DocNameFilterjsForCD").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterCDData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllDocNamesForCW() {
  var names = $(".DocNamejsForCW").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".DocNameFilterjsForCW").append("<li><input class='CheckDocNameForCW' type='checkbox' checked data-attribute='DocName' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".DocNameFilterjsForCW").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterCWData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllDocNamesForAL() {
  var names = $(".DocNamejsForAL").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".DocNameFilterjsForAL").append("<li><input class='CheckDocNameForAL' type='checkbox' checked data-attribute='DocName' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".DocNameFilterjsForAL").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterALData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllDocNamesForLD() {
  var names = $(".DocNamejsForLD").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".DocNameFilterjsForLD").append("<li><input class='CheckDocNameForLD' type='checkbox' checked data-attribute='DocName' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".DocNameFilterjsForLD").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterLDData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllDocNamesForCL() {
  var names = $(".DocNamejsForCL").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".DocNameFilterjsForCL").append("<li><input class='CheckDocNameForCL' type='checkbox' checked data-attribute='DocName' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".DocNameFilterjsForCL").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterCLData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllDocNamesForOD() {
  var names = $(".DocNamejsForOD").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".DocNameFilterjsForOD").append("<li><input class='CheckDocNameForOD' type='checkbox' checked data-attribute='DocName' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".DocNameFilterjsForOD").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterODData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}

//---------------ShowAllDocTypes------------------------
function ShowAllDocTypesForDoc() {
  var names = $(".DocTypejsForDoc").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".DocTypeFilterjsForDoc").append("<li><input class='CheckDocTypeForDoc' type='checkbox' checked data-attribute='DocType' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".DocTypeFilterjsForDoc").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterDocData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllDocTypesForEL() {
  var names = $(".DocTypejsForEL").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".DocTypeFilterjsForEL").append("<li><input class='CheckDocTypeForEL' type='checkbox' checked data-attribute='DocType' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".DocTypeFilterjsForEL").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterELData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllDocTypesForSOW() {
  var names = $(".DocTypejsForSOW").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".DocTypeFilterjsForSOW").append("<li><input class='CheckDocTypeForSOW' type='checkbox' checked data-attribute='DocType' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".DocTypeFilterjsForSOW").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterSOWData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllDocTypesForMSA() {
  var names = $(".DocTypejsForMSA").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".DocTypeFilterjsForMSA").append("<li><input class='CheckDocTypeForMSA' type='checkbox' checked data-attribute='DocType' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".DocTypeFilterjsForMSA").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterMSAData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllDocTypesForMemo() {
  var names = $(".DocTypejsForMemo").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".DocTypeFilterjsForMemo").append("<li><input class='CheckDocTypeForMemo' type='checkbox' checked data-attribute='DocType' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".DocTypeFilterjsForMemo").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterMemoData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllDocTypesForCD() {
  var names = $(".DocTypejsForCD").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".DocTypeFilterjsForCD").append("<li><input class='CheckDocTypeForCD' type='checkbox' checked data-attribute='DocType' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".DocTypeFilterjsForCD").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterCDData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllDocTypesForCW() {
  var names = $(".DocTypejsForCW").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".DocTypeFilterjsForCW").append("<li><input class='CheckDocTypeForCW' type='checkbox' checked data-attribute='DocType' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".DocTypeFilterjsForCW").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterCWData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllDocTypesForAL() {
  var names = $(".DocTypejsForAL").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".DocTypeFilterjsForAL").append("<li><input class='CheckDocTypeForAL' type='checkbox' checked data-attribute='DocType' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".DocTypeFilterjsForAL").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterALData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllDocTypesForLD() {
  var names = $(".DocTypejsForLD").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".DocTypeFilterjsForLD").append("<li><input class='CheckDocTypeForLD' type='checkbox' checked data-attribute='DocType' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".DocTypeFilterjsForLD").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterLDData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllDocTypesForCL() {
  var names = $(".DocTypejsForCL").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".DocTypeFilterjsForCL").append("<li><input class='CheckDocTypeForCL' type='checkbox' checked data-attribute='DocType' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".DocTypeFilterjsForCL").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterCLData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllDocTypesForOD() {
  var names = $(".DocTypejsForOD").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".DocTypeFilterjsForOD").append("<li><input class='CheckDocTypeForOD' type='checkbox' checked data-attribute='DocType' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".DocTypeFilterjsForOD").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterODData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}

//----------------ShowAllAuthor-----------------------
function ShowAllAuthorForDoc() {
  var names = $(".AuthorjsForDoc").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".AuthorFilterjsForDoc").append("<li><input class='CheckAuthorForDoc' type='checkbox' checked data-attribute='Author' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".AuthorFilterjsForDoc").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterDocData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllAuthorForEL() {
  var names = $(".AuthorjsForEL").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".AuthorFilterjsForEL").append("<li><input class='CheckAuthorForEL' type='checkbox' checked data-attribute='Author' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".AuthorFilterjsForEL").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterELData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllAuthorForSOW() {
  var names = $(".AuthorjsForSOW").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".AuthorFilterjsForSOW").append("<li><input class='CheckAuthorForSOW' type='checkbox' checked data-attribute='Author' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".AuthorFilterjsForSOW").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterSOWData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllAuthorForMSA() {
  var names = $(".AuthorjsForMSA").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".AuthorFilterjsForMSA").append("<li><input class='CheckAuthorForMSA' type='checkbox' checked data-attribute='Author' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".AuthorFilterjsForMSA").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterMSAData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllAuthorForMemo() {
  var names = $(".AuthorjsForMemo").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".AuthorFilterjsForMemo").append("<li><input class='CheckAuthorForMemo' type='checkbox' checked data-attribute='Author' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".AuthorFilterjsForMemo").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterMemoData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllAuthorForCD() {
  var names = $(".AuthorjsForCD").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".AuthorFilterjsForCD").append("<li><input class='CheckAuthorForCD' type='checkbox' checked data-attribute='Author' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".AuthorFilterjsForCD").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterCDData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllAuthorForCW() {
  var names = $(".AuthorjsForCW").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".AuthorFilterjsForCW").append("<li><input class='CheckAuthorForCW' type='checkbox' checked data-attribute='Author' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".AuthorFilterjsForCW").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterCWData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllAuthorForAL() {
  var names = $(".AuthorjsForAL").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".AuthorFilterjsForAL").append("<li><input class='CheckAuthorForAL' type='checkbox' checked data-attribute='Author' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".AuthorFilterjsForAL").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterALData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllAuthorForLD() {
  var names = $(".AuthorjsForLD").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".AuthorFilterjsForLD").append("<li><input class='CheckAuthorForLD' type='checkbox' checked data-attribute='Author' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".AuthorFilterjsForLD").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterLDData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllAuthorForCL() {
  var names = $(".AuthorjsForCL").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".AuthorFilterjsForCL").append("<li><input class='CheckAuthorForCL' type='checkbox' checked data-attribute='Author' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".AuthorFilterjsForCL").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterCLData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllAuthorForOD() {
  var names = $(".AuthorjsForOD").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".AuthorFilterjsForOD").append("<li><input class='CheckAuthorForOD' type='checkbox' checked data-attribute='Author' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".AuthorFilterjsForOD").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterODData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}

//----------------ShowAllStatus-------------------------
function ShowAllStatusForDoc() {
  var names = $(".StatusjsForDoc").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".StatusFilterjsForDoc").append("<li><input class='CheckStatusForDoc' type='checkbox' checked data-attribute='Status' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".StatusFilterjsForDoc").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterDocData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllStatusForEL() {
  var names = $(".StatusjsForEL").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".StatusFilterjsForEL").append("<li><input class='CheckStatusForEL' type='checkbox' checked data-attribute='Status' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".StatusFilterjsForEL").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterELData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllStatusForSOW() {
  var names = $(".StatusjsForSOW").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".StatusFilterjsForSOW").append("<li><input class='CheckStatusForSOW' type='checkbox' checked data-attribute='Status' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".StatusFilterjsForSOW").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterSOWData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllStatusForMSA() {
  var names = $(".StatusjsForMSA").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".StatusFilterjsForMSA").append("<li><input class='CheckStatusForMSA' type='checkbox' checked data-attribute='Status' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".StatusFilterjsForMSA").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterMSAData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllStatusForMemo() {
  var names = $(".StatusjsForMemo").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".StatusFilterjsForMemo").append("<li><input class='CheckStatusForMemo' type='checkbox' checked data-attribute='Status' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".StatusFilterjsForMemo").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterMemoData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllStatusForCD() {
  var names = $(".StatusjsForCD").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".StatusFilterjsForCD").append("<li><input class='CheckStatusForCD' type='checkbox' checked data-attribute='Status' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".StatusFilterjsForCD").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterCDData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllStatusForCW() {
  var names = $(".StatusjsForCW").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".StatusFilterjsForCW").append("<li><input class='CheckStatusForCW' type='checkbox' checked data-attribute='Status' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".StatusFilterjsForCW").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterCWData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllStatusForAL() {
  var names = $(".StatusjsForAL").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".StatusFilterjsForAL").append("<li><input class='CheckStatusForAL' type='checkbox' checked data-attribute='Status' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".StatusFilterjsForAL").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterALData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllStatusForLD() {
  var names = $(".StatusjsForLD").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".StatusFilterjsForLD").append("<li><input class='CheckStatusForLD' type='checkbox' checked data-attribute='Status' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".StatusFilterjsForLD").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterLDData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllStatusForCL() {
  var names = $(".StatusjsForCL").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".StatusFilterjsForCL").append("<li><input class='CheckStatusForCL' type='checkbox' checked data-attribute='Status' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".StatusFilterjsForCL").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterCLData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}
function ShowAllStatusForOD() {
  var names = $(".StatusjsForOD").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".StatusFilterjsForOD").append("<li><input class='CheckStatusForOD' type='checkbox' checked data-attribute='Status' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".StatusFilterjsForOD").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterODData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}

// ---------------------------------
function getUnique(array) {
  var uniqueArray = [];

  // Loop through array values
  for (let i = 0; i < array.length; i++) {
    if (uniqueArray.indexOf(array[i]) === -1) {
      uniqueArray.push(array[i]);
    }
  }
  return uniqueArray;
}

//ClientNameFilterjs
function filterFunction(filterSearchBox, DropdowntoFilter) {
  var input, filter, ul, li, a, i, txtValue;
  input = document.getElementById(filterSearchBox);
  filter = input.value.toUpperCase();
  ul = document.getElementById(DropdowntoFilter);
  li = ul.getElementsByTagName("li");
  for (i = 1; i < li.length; i++) {
    a = li[i].getElementsByTagName("a")[0];
    if (a !== undefined) {
      txtValue = a.textContent || a.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  }
}
// --------------Filter Data----------------------------

function filterDocData() {
  var list = [];

  $('.CheckClientForDoc,.CheckEngagementForDoc,.CheckDocNameForDoc,.CheckDocTypeForDoc,.CheckAuthorForDoc,.CheckStatusForDoc,.CheckAllClientsDoc,.CheckAllEngDoc,.CheckAllDocName,.CheckAllDocType,.CheckAllAuthor,.CheckAllStatusForDoc').each(function () {
    if ($(this).is(':checked')) {
      list.push({
        "attribute": $(this).attr("data-attribute"),
        "value": $(this).attr("data-value")
      })
    }
  });

  $(".FilterDocDatajs").filter(function () {
    $("#NoDataMessage").addClass('hide');
    var result = false;
    var $this = $(this);

    var routeName;
    routeName = $(location).attr('href').split('/')[3];
    if (routeName == 'document' || routeName == 'favorite') {
      result = filterText('ClientNamejsForDoc', 'ClientName', $this, list);
      result = result && filterText('EngagementjsForDoc', 'Engagement', $this, list);
      result = result && filterText('DocNamejsForDoc', 'DocName', $this, list);
    }
    else{
      result = filterText('DocNamejsForDoc', 'DocName', $this, list);
    }
    result = result && filterText('DocTypejsForDoc', 'DocType', $this, list);

    if (routeName == 'EngagementSummary') {
      result = result && filterText('AuthorjsForDoc', 'Author', $this, list);
    }
    result = result && filterText('StatusjsForDoc', 'Status', $this, list);

    if (result == false) {
      $("#NoDataMessage").removeClass('hide');
    }


    $(this).toggle(result);
    var originalFilterLength = $(".FilterDocDatajs").length;
    var hiddenFilterdata = $('.FilterDocDatajs').filter(function () {
      return $(this).css('display') == 'none';
    }).length;

    if (originalFilterLength == hiddenFilterdata) {
      $("#NoDataMessage").removeClass('hide');
    }
    else
    { $("#NoDataMessage").addClass('hide');}
  });
}
function filterELData() {
  var list = [];

  $('.CheckClientForEL,.CheckEngagementForEL,.CheckDocNameForEL,.CheckDocTypeForEL,.CheckAuthorForEL,.CheckStatusForEL,.CheckAllClientsEL,.CheckAllEngEL,.CheckAllDocName,.CheckAllDocType,.CheckAllAuthor,.CheckAllStatusForEL').each(function () {
    if ($(this).is(':checked')) {
      list.push({
        "attribute": $(this).attr("data-attribute"),
        "value": $(this).attr("data-value")
      })
    }
  });

  $(".FilterDocDatajs").filter(function () {
    var result = false;
    var $this = $(this);
    var LocAddr = $(location).attr('href').split('/');
    var count = LocAddr.length;
    var routeName = LocAddr[count - 1];
    if (routeName == 'document' || routeName == 'favorite') {
      result = filterText('ClientNamejsForEL', 'ClientName', $this, list);
      result = result && filterText('EngagementjsForEL', 'Engagement', $this, list);
      result = result && filterText('DocNamejsForEL', 'DocName', $this, list);
    }
    else{
      result = filterText('DocNamejsForEL', 'DocName', $this, list);
    }
    result = result && filterText('DocTypejsForEL', 'DocType', $this, list);

    if (routeName == 'EngagementSummary') {
      result = result && filterText('AuthorjsForEL', 'Author', $this, list);
    }
    result = result && filterText('StatusjsForEL', 'Status', $this, list);



    $(this).toggle(result);
    var originalFilterLength = $(".FilterDocDatajs").length;
    var hiddenFilterdata = $('.FilterDocDatajs').filter(function () {
      return $(this).css('display') == 'none';
    }).length;

    if (originalFilterLength == hiddenFilterdata) {
      $("#NoDataMessage").show();
    }
  });
}
function filterSOWData() {
  var list = [];

  $('.CheckClientForSOW,.CheckEngagementForSOW,.CheckDocNameForSOW,.CheckDocTypeForSOW,.CheckAuthorForSOW,.CheckStatusForSOW,.CheckAllClientsSOW,.CheckAllEngSOW,.CheckAllDocName,.CheckAllDocType,.CheckAllAuthor,.CheckAllStatusForSOW').each(function () {
    if ($(this).is(':checked')) {
      list.push({
        "attribute": $(this).attr("data-attribute"),
        "value": $(this).attr("data-value")
      })
    }
  });

  $(".FilterSOWDatajs").filter(function () {
    var result = false;
    var $this = $(this);
    var LocAddr = $(location).attr('href').split('/');
    var count = LocAddr.length;
    var routeName = LocAddr[count - 1];
    if (routeName == 'document' || routeName == 'favorite') {
      result = filterText('ClientNamejsForSOW', 'ClientName', $this, list);
      result = result && filterText('EngagementjsForSOW', 'Engagement', $this, list);
      result = result && filterText('DocNamejsForSOW', 'DocName', $this, list);
    }
    else{
      result = filterText('DocNamejsForSOW', 'DocName', $this, list);
    }
    result = result && filterText('DocTypejsForSOW', 'DocType', $this, list);

    if (routeName == 'EngagementSummary') {
      result = result && filterText('AuthorjsForSOW', 'Author', $this, list);
    }
    result = result && filterText('StatusjsForSOW', 'Status', $this, list);



    $(this).toggle(result);
    var originalFilterLength = $(".FilterSOWDatajs").length;
    var hiddenFilterdata = $('.FilterSOWDatajs').filter(function () {
      return $(this).css('display') == 'none';
    }).length;

    if (originalFilterLength == hiddenFilterdata) {
      $("#NoDataMessage").show();
    }
  });
}
function filterMSAData() {
  var list = [];

  $('.CheckClientForMSA,.CheckEngagementForMSA,.CheckDocNameForMSA,.CheckDocTypeForMSA,.CheckAuthorForMSA,.CheckStatusForMSA,.CheckAllClientsMSA,.CheckAllEngMSA,.CheckAllDocName,.CheckAllDocType,.CheckAllAuthor,.CheckAllStatusForMSA').each(function () {
    if ($(this).is(':checked')) {
      list.push({
        "attribute": $(this).attr("data-attribute"),
        "value": $(this).attr("data-value")
      })
    }
  });

  $(".FilterMSADatajs").filter(function () {
    var result = false;
    var $this = $(this);
    var LocAddr = $(location).attr('href').split('/');
    var count = LocAddr.length;
    routeName = LocAddr[count - 1];
    if (routeName == 'document' || routeName == 'favorite') {
      result = filterText('ClientNamejsForMSA', 'ClientName', $this, list);
      result = result && filterText('EngagementjsForMSA', 'Engagement', $this, list);
      result = result && filterText('DocNamejsForMSA', 'DocName', $this, list);
    }
    else{
      result = filterText('DocNamejsForMSA', 'DocName', $this, list);
    }
    result = result && filterText('DocTypejsForMSA', 'DocType', $this, list);

    if (routeName == 'EngagementSummary') {
      result = result && filterText('AuthorjsForMSA', 'Author', $this, list);
    }
    result = result && filterText('StatusjsForMSA', 'Status', $this, list);



    $(this).toggle(result);
    var originalFilterLength = $(".FilterMSADatajs").length;
    var hiddenFilterdata = $('.FilterMSADatajs').filter(function () {
      return $(this).css('display') == 'none';
    }).length;

    if (originalFilterLength == hiddenFilterdata) {
      $("#NoDataMessage").show();
    }
  });
}
function filterMemoData() {
  var list = [];

  $('.CheckClientForMemo,.CheckEngagementForMemo,.CheckDocNameForMemo,.CheckDocTypeForMemo,.CheckAuthorForMemo,.CheckStatusForMemo,.CheckAllClientsMemo,.CheckAllEngMemo,.CheckAllDocName,.CheckAllDocType,.CheckAllAuthor,.CheckAllStatusForMemo').each(function () {
    if ($(this).is(':checked')) {
      list.push({
        "attribute": $(this).attr("data-attribute"),
        "value": $(this).attr("data-value")
      })
    }
  });

  $(".FilterMemoDatajs").filter(function () {
    var result = false;
    var $this = $(this);
    var LocAddr = $(location).attr('href').split('/');
    var count = LocAddr.length;
    routeName = LocAddr[count - 1];
    if (routeName == 'document' || routeName == 'favorite') {
      result = filterText('ClientNamejsForMemo', 'ClientName', $this, list);
      result = result && filterText('EngagementjsForMemo', 'Engagement', $this, list);
      result = result && filterText('DocNamejsForMemo', 'DocName', $this, list);
    }
    else{
      result = filterText('DocNamejsForMemo', 'DocName', $this, list);
    }
    result = result && filterText('DocTypejsForMemo', 'DocType', $this, list);

    if (routeName == 'EngagementSummary') {
      result = result && filterText('AuthorjsForMemo', 'Author', $this, list);
    }
    result = result && filterText('StatusjsForMemo', 'Status', $this, list);



    $(this).toggle(result);
    var originalFilterLength = $(".FilterMemoDatajs").length;
    var hiddenFilterdata = $('.FilterMemoDatajs').filter(function () {
      return $(this).css('display') == 'none';
    }).length;

    if (originalFilterLength == hiddenFilterdata) {
      $("#NoDataMessage").show();
    }
  });
}
function filterCDData() {
  var list = [];

  $('.CheckClientForCD,.CheckEngagementForCD,.CheckDocNameForCD,.CheckDocTypeForCD,.CheckAuthorForCD,.CheckStatusForCD,.CheckAllClientsCD,.CheckAllEngCD,.CheckAllDocName,.CheckAllDocType,.CheckAllAuthor,.CheckAllStatusForCD').each(function () {
    if ($(this).is(':checked')) {
      list.push({
        "attribute": $(this).attr("data-attribute"),
        "value": $(this).attr("data-value")
      })
    }
  });

  $(".FilterCDDatajs").filter(function () {
    var result = false;
    var $this = $(this);
    var LocAddr = $(location).attr('href').split('/');
    var count = LocAddr.length;
    routeName = LocAddr[count - 1];
    if (routeName == 'document' || routeName == 'favorite') {
      result = filterText('ClientNamejsForCD', 'ClientName', $this, list);
      result = result && filterText('EngagementjsForCD', 'Engagement', $this, list);
      result = result && filterText('DocNamejsForCD', 'DocName', $this, list);
    }
    else{
      result = filterText('DocNamejsForCD', 'DocName', $this, list);
    }
    result = result && filterText('DocTypejsForCD', 'DocType', $this, list);

    if (routeName == 'EngagementSummary') {
      result = result && filterText('AuthorjsForCD', 'Author', $this, list);
    }
    result = result && filterText('StatusjsForCD', 'Status', $this, list);



    $(this).toggle(result);
    var originalFilterLength = $(".FilterCDDatajs").length;
    var hiddenFilterdata = $('.FilterCDDatajs').filter(function () {
      return $(this).css('display') == 'none';
    }).length;

    if (originalFilterLength == hiddenFilterdata) {
      $("#NoDataMessage").show();
    }
  });
}
function filterCWData() {
  var list = [];

  $('.CheckClientForCW,.CheckEngagementForCW,.CheckDocNameForCW,.CheckDocTypeForCW,.CheckAuthorForCW,.CheckStatusForCW,.CheckAllClientsCW,.CheckAllEngCW,.CheckAllDocName,.CheckAllDocType,.CheckAllAuthor,.CheckAllStatusForCW').each(function () {
    if ($(this).is(':checked')) {
      list.push({
        "attribute": $(this).attr("data-attribute"),
        "value": $(this).attr("data-value")
      })
    }
  });

  $(".FilterCWDatajs").filter(function () {
    var result = false;
    var $this = $(this);
    var LocAddr = $(location).attr('href').split('/');
    var count = LocAddr.length;
    routeName = LocAddr[count - 1];
    if (routeName == 'document' || routeName == 'favorite') {
      result = filterText('ClientNamejsForCW', 'ClientName', $this, list);
      result = result && filterText('EngagementjsForCW', 'Engagement', $this, list);
      result = result && filterText('DocNamejsForCW', 'DocName', $this, list);
    }
    else{
      result = filterText('DocNamejsForCW', 'DocName', $this, list);
    }
    result = result && filterText('DocTypejsForCW', 'DocType', $this, list);

    if (routeName == 'EngagementSummary') {
      result = result && filterText('AuthorjsForCW', 'Author', $this, list);
    }
    result = result && filterText('StatusjsForCW', 'Status', $this, list);



    $(this).toggle(result);
    var originalFilterLength = $(".FilterCWDatajs").length;
    var hiddenFilterdata = $('.FilterCWDatajs').filter(function () {
      return $(this).css('display') == 'none';
    }).length;

    if (originalFilterLength == hiddenFilterdata) {
      $("#NoDataMessage").show();
    }
  });
}
function filterALData() {
  var list = [];

  $('.CheckClientForAL,.CheckEngagementForAL,.CheckDocNameForAL,.CheckDocTypeForAL,.CheckAuthorForAL,.CheckStatusForAL,.CheckAllClientsAL,.CheckAllEngAL,.CheckAllDocName,.CheckAllDocType,.CheckAllAuthor,.CheckAllStatusForAL').each(function () {
    if ($(this).is(':checked')) {
      list.push({
        "attribute": $(this).attr("data-attribute"),
        "value": $(this).attr("data-value")
      })
    }
  });

  $(".FilterALDatajs").filter(function () {
    var result = false;
    var $this = $(this);
    var LocAddr = $(location).attr('href').split('/');
    var count = LocAddr.length;
    routeName = LocAddr[count - 1];
    if (routeName == 'document' || routeName == 'favorite') {
      result = filterText('ClientNamejsForAL', 'ClientName', $this, list);
      result = result && filterText('EngagementjsForAL', 'Engagement', $this, list);
      result = result && filterText('DocNamejsForAL', 'DocName', $this, list);
    }
    else{
      result = filterText('DocNamejsForAL', 'DocName', $this, list);
    }
    result = result && filterText('DocTypejsForAL', 'DocType', $this, list);

    if (routeName == 'EngagementSummary') {
      result = result && filterText('AuthorjsForAL', 'Author', $this, list);
    }
    result = result && filterText('StatusjsForAL', 'Status', $this, list);



    $(this).toggle(result);
    var originalFilterLength = $(".FilterALDatajs").length;
    var hiddenFilterdata = $('.FilterALDatajs').filter(function () {
      return $(this).css('display') == 'none';
    }).length;

    if (originalFilterLength == hiddenFilterdata) {
      $("#NoDataMessage").show();
    }
  });
}
function filterLDData() {
  var list = [];

  $('.CheckClientForLD,.CheckEngagementForLD,.CheckDocNameForLD,.CheckDocTypeForLD,.CheckAuthorForLD,.CheckStatusForLD,.CheckAllClientsLD,.CheckAllEngLD,.CheckAllDocName,.CheckAllDocType,.CheckAllAuthor,.CheckAllStatusForLD').each(function () {
    if ($(this).is(':checked')) {
      list.push({
        "attribute": $(this).attr("data-attribute"),
        "value": $(this).attr("data-value")
      })
    }
  });

  $(".FilterLDDatajs").filter(function () {
    var result = false;
    var $this = $(this);
    var LocAddr = $(location).attr('href').split('/');
    var count = LocAddr.length;
    routeName = LocAddr[count - 1];
    if (routeName == 'document' || routeName == 'favorite') {
      result = filterText('ClientNamejsForLD', 'ClientName', $this, list);
      result = result && filterText('EngagementjsForLD', 'Engagement', $this, list);
      result = result && filterText('DocNamejsForLD', 'DocName', $this, list);
    }
    else{
      result = filterText('DocNamejsForLD', 'DocName', $this, list);
    }
    result = result && filterText('DocTypejsForLD', 'DocType', $this, list);

    if (routeName == 'EngagementSummary') {
      result = result && filterText('AuthorjsForLD', 'Author', $this, list);
    }
    result = result && filterText('StatusjsForLD', 'Status', $this, list);



    $(this).toggle(result);
    var originalFilterLength = $(".FilterLDDatajs").length;
    var hiddenFilterdata = $('.FilterLDDatajs').filter(function () {
      return $(this).css('display') == 'none';
    }).length;

    if (originalFilterLength == hiddenFilterdata) {
      $("#NoDataMessage").show();
    }
  });
}
function filterCLData() {
  var list = [];

  $('.CheckClientForCL,.CheckEngagementForCL,.CheckDocNameForCL,.CheckDocTypeForCL,.CheckAuthorForCL,.CheckStatusForCL,.CheckAllClientsCL,.CheckAllEngCL,.CheckAllDocName,.CheckAllDocType,.CheckAllAuthor,.CheckAllStatusForCL').each(function () {
    if ($(this).is(':checked')) {
      list.push({
        "attribute": $(this).attr("data-attribute"),
        "value": $(this).attr("data-value")
      })
    }
  });

  $(".FilterCLDatajs").filter(function () {
    var result = false;
    var $this = $(this);
    var LocAddr = $(location).attr('href').split('/');
    var count = LocAddr.length;
    routeName = LocAddr[count - 1];
    if (routeName == 'document' || routeName == 'favorite') {
      result = filterText('ClientNamejsForCL', 'ClientName', $this, list);
      result = result && filterText('EngagementjsForCL', 'Engagement', $this, list);
      result = result && filterText('DocNamejsForCL', 'DocName', $this, list);
    }
    else{
      result = filterText('DocNamejsForCL', 'DocName', $this, list);
    }
    result = result && filterText('DocTypejsForCL', 'DocType', $this, list);

    if (routeName == 'EngagementSummary') {
      result = result && filterText('AuthorjsForCL', 'Author', $this, list);
    }
    result = result && filterText('StatusjsForCL', 'Status', $this, list);



    $(this).toggle(result);
    var originalFilterLength = $(".FilterCLDatajs").length;
    var hiddenFilterdata = $('.FilterCLDatajs').filter(function () {
      return $(this).css('display') == 'none';
    }).length;

    if (originalFilterLength == hiddenFilterdata) {
      $("#NoDataMessage").show();
    }
  });
}
function filterODData() {
  var list = [];

  $('.CheckClientForOD,.CheckEngagementForOD,.CheckDocNameForOD,.CheckDocTypeForOD,.CheckAuthorForOD,.CheckStatusForOD,.CheckAllClientsOD,.CheckAllEngOD,.CheckAllDocName,.CheckAllDocType,.CheckAllAuthor,.CheckAllStatusForOD').each(function () {
    if ($(this).is(':checked')) {
      list.push({
        "attribute": $(this).attr("data-attribute"),
        "value": $(this).attr("data-value")
      })
    }
  });

  $(".FilterODDatajs").filter(function () {
    var result = false;
    var $this = $(this);
    var LocAddr = $(location).attr('href').split('/');
    var count = LocAddr.length;
    routeName = LocAddr[count - 1];
    if (routeName == 'document' || routeName == 'favorite') {
      result = filterText('ClientNamejsForOD', 'ClientName', $this, list);
      result = result && filterText('EngagementjsForOD', 'Engagement', $this, list);
      result = result && filterText('DocNamejsForOD', 'DocName', $this, list);
    }
    else{
      result = filterText('DocNamejsForOD', 'DocName', $this, list);
    }
    result = result && filterText('DocTypejsForOD', 'DocType', $this, list);

    if (routeName == 'EngagementSummary') {
      result = result && filterText('AuthorjsForOD', 'Author', $this, list);
    }
    result = result && filterText('StatusjsForOD', 'Status', $this, list);



    $(this).toggle(result);
    var originalFilterLength = $(".FilterODDatajs").length;
    var hiddenFilterdata = $('.FilterODDatajs').filter(function () {
      return $(this).css('display') == 'none';
    }).length;

    if (originalFilterLength == hiddenFilterdata) {
      $("#NoDataMessage").show();
    }
  });
}
// ----------------------------------------
function filterText(clsForFilter, attrName, obj, list) {
  //clsForFilter - ClientNameForFilter
  //attrName -  ClientName
  var result = false;
  var value = $(obj).find('.' + clsForFilter).text();

  var newList = $(list).filter(function (e, eachObj) {
    return (eachObj.attribute == ('chkAll-' + attrName)) ||
      (eachObj.attribute == attrName && eachObj.value == value);
  });

  if (newList.length > 0) {
    result = true;

  }

  return result;
}
