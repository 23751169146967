
   // listview function
  
  function applyListView(fnCallBack) {
  
    $(".searchbox").on("keyup", function () {
      mySearch(this.value, "searchDatajs");
    });
  
    $(".expand-icon").on("click", function () {
      toggleIcon();
      collapseOrExpandCardView();
    });
      fnCallBack();
  
      $('.ReviewDatatable').DataTable({
        bLengthChange: false,
        searching: false,
        iDisplayLength: 2
      });
      // $('.DocumentDataTable').DataTable({
      //   bLengthChange: false,
      //   searching: false,
      //   iDisplayLength: 5
      // });
  }
  function setValueToEPContactCard(a) {
    $('.headingFont').html(a);
  }
  //Filtering on card view code
  function CheckUncheckAllForacptdoc(chkAll, checkbox) {
    $('.' + chkAll).on('change', function () {
      $('.' + checkbox).prop('checked', $(this).prop("checked"));
    });
    //deselect "checked all", if one of the listed checkbox product is unchecked amd select "checked all" if all of the listed checkbox product is checked
    $('.' + checkbox).change(function () { //".checkbox" change
      if ($('.' + checkbox + ':checked').length == $('.' + checkbox).length) {
        $('.' + chkAll).prop('checked', true);
      } else {
        $('.' + chkAll).prop('checked', false);
      }
    });
  }
//ClientNameForacptdocFilterjs

function filterFunctionForacptdoc(filterSearchBox, DropdowntoFilter) {
    var input, filter, ul, li, a, i, txtValue;
    input = document.getElementById(filterSearchBox);
    filter = input.value.toUpperCase();
    ul = document.getElementById(DropdowntoFilter);
    li = ul.getElementsByTagName("li");
    for (i = 1; i < li.length; i++) {
      a = li[i].getElementsByTagName("a")[0];
      txtValue = a.textContent || a.innerText;
      if (a != undefined){
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          li[i].style.display = "";
        } else {
          li[i].style.display = "none";
        }
      }
    }
  }
  
  function CloseFilteracptdoc(e) {
    $(e).parents('.dropdown').find('button.dropdown-toggle').dropdown('toggle');
  }
  
  function ShowAllClientNameForacptdoc() {
    var names = $(".ClientNamejsForacptdoc").map(function () { return $(this).text(); });
  
    var distinctNames = getUniqueArrayForacptdoc(names); //$.unique(names);
    $(distinctNames).each(function (i, obj) {
      $(".ClientNameFilterjsForacptdoc").append("<li><input class='CheckClientReview' type='checkbox' checked data-attribute='ClientName' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
    });
    $(".ClientNameFilterjsForacptdoc").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterDataForacptdoc()> <input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilterReview(this)></div>");
  }
  
  
  function getUniqueArrayReview(array) {
    var uniqueArray = [];
  
    // Loop through array values
    for (let i = 0; i < array.length; i++) {
      if (uniqueArray.indexOf(array[i]) === -1) {
        uniqueArray.push(array[i]);
      }
    }
    return uniqueArray;
  }
  
  
  function getUniqueArrayForacptdoc(array) {
    var uniqueArray = [];
  
    // Loop through array values
    for (let i = 0; i < array.length; i++) {
      if (uniqueArray.indexOf(array[i]) === -1) {
        uniqueArray.push(array[i]);
      }
    }
    return uniqueArray;
  }
  
  function ShowAllEngagementsForacptdoc() {
    var names = $(".EngagementjsForacptdoc").map(function () { return $(this).text(); });
  
    var distinctNames = getUniqueArrayReview(names); //$.unique(names);
    $(distinctNames).each(function (i, obj) {
      $(".EngagementFilterjsForacptdoc").append("<li><input class='CheckEngagementForacptdoc' type='checkbox' checked data-attribute='EngagementForacptdoc' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
    });
    $(".EngagementFilterjsForacptdoc").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterDataForacptdoc()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilteracptdoc(this)></div>");
  }
  
  function ShowAllSolutionCodeForacptdoc() {
    var names = $(".SolutionCodejsForacptdoc").map(function () { return $(this).text(); });
  
    var distinctNames = getUniqueArrayReview(names); //$.unique(names);
    $(distinctNames).each(function (i, obj) {
      $(".SolutionCodeFilterjsForacptdoc").append("<li><input class='CheckSolutionCodeForacptdoc' type='checkbox' checked data-attribute='SolutionCodeForacptdoc' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
    });
    $(".SolutionCodeFilterjsForacptdoc").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterDataForacptdoc()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilteracptdoc(this)></div>");
  }
  
  function ShowAllEPForacptdoc() {
    var names = $(".EPjsForFilterForacptdoc").map(function () { return $(this).text(); });
  
    var distinctNames = getUniqueArrayReview(names); //$.unique(names);
    $(distinctNames).each(function (i, obj) {
      $(".EPFilterjsForEngForacptdoc").append("<li role='presentation'><input class='CheckEPForacptdoc' type='checkbox' checked data-attribute='EPForacptdoc' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
    });
    $(".EPFilterjsForEngForacptdoc").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterDataForacptdoc()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilteracptdoc(this)></div>");
  }
  
  function ShowAllStatusForacptdoc() {
    var names = $(".StatusjsForFilterForacptdoc").map(function () { return $(this).text(); });
  
    var distinctNames = getUniqueArrayReview(names); //$.unique(names);
    $(distinctNames).each(function (i, obj) {
      $(".StatusFilterjsForacptdoc").append("<li><input class='CheckStatusForacptdoc' type='checkbox' checked data-attribute='StatusForacptdoc' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
    });
    $(".StatusFilterjsForacptdoc").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterDataForacptdoc()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilteracptdoc(this)></div>");
  }
  
  //Filtering on card view code
  function CheckUncheckAllForacptdoc(chkAll, checkbox) {
    $('.' + chkAll).on('change', function () {
      $('.' + checkbox).prop('checked', $(this).prop("checked"));
    });
    //deselect "checked all", if one of the listed checkbox product is unchecked amd select "checked all" if all of the listed checkbox product is checked
    $('.' + checkbox).change(function () { //".checkbox" change
      if ($('.' + checkbox + ':checked').length == $('.' + checkbox).length) {
        $('.' + chkAll).prop('checked', true);
      } else {
        $('.' + chkAll).prop('checked', false);
      }
    });
  }
  
  function filterTextForacptdoc(clsForFilter, attrName, objf, list) {
    //clsForFilter - ClientNameForFilter
    //attrName -  ClientName
    var result = false;
    var value = $(objf).find('.' + clsForFilter).text();
  
    var newList = $(list).filter(function (e, objfn) {
      return (objfn.attribute == ('chkAll-' + attrName)) ||
        (objfn.attribute == attrName && objfn.value == value);
    });
  
    if (newList.length > 0) {
      result = true;
    }
    return result;
  }
  
  function filterDataForacptdoc() {
    var list = [];
  
    $('.CheckEngagementForacptdoc,.CheckSolutionCodeForacptdoc,.CheckStatusForacptdoc,.CheckEPForacptdoc,.CheckAllEngForacptdoc,.CheckAllSolutionCodeForacptdoc,.CheckAllStatusForacptdoc,.CheckAllEPForacptdoc').each(function () {
      if ($(this).is(':checked')) {
        list.push({
          "attribute": $(this).attr("data-attribute"),
          "value": $(this).attr("data-value")
        })
      }
    });
  
    $(".FilterEngDatajsForacptdoc").filter(function () {
      $("#NoDataMessageForacptdoc").addClass('hide');
      var result = false;
      var $this = $(this);
    //   var LocAddr = $(location).attr('href').split('/'); 
    //   routeName = LocAddr[3];
    //   if (routeName == 'engagement') {
    //     result = filterTextForacptdoc('ClientNameEngForacptdoc', 'ClientNameForAccDoc', $this, list);
    //     result = result && filterTextForacptdoc('EngagementjsForacptdoc', 'EngagementForacptdoc', $this, list);
    //   }
    //   else if (routeName = "client-summary") {
    //     result = filterTextForacptdoc('EngagementjsForacptdoc', 'EngagementForacptdoc', $this, list);
    //   }
    result = filterTextForacptdoc('EngagementjsForacptdoc', 'EngagementForacptdoc', $this, list);

      result = result && filterTextForacptdoc('SolutionCodejsForacptdoc', 'SolutionCodeForacptdoc', $this, list);
  
      result = result && filterTextForacptdoc('StatusjsForFilterForacptdoc', 'StatusForacptdoc', $this, list);
      result = result && filterTextForacptdoc('EPjsForFilterForacptdoc', 'EPForacptdoc', $this, list);
  
      if (result == false) {
        $("#NoDataMessageForacptdoc").removeClass('hide');
      }
  
      $(this).toggle(result);
        var originalFilterLength = $(".FilterEngDatajsForacptdoc").length;
        var hiddenFilterdata = $('.FilterEngDatajsForacptdoc').filter(function () {
          return $(this).css('display') == 'none';
        }).length;
  
        if (originalFilterLength == hiddenFilterdata) {
          $("#NoDataMessageForacptdoc").removeClass('hide');
        }
        else
        { $("#NoDataMessageForacptdoc").addClass('hide');}
      });
  
  }
  