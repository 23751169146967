
function mySearch(text, classname) {
    // ".display tr"
    var value = text.toLowerCase();
    $("." + classname).filter(function () {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
    });
  }
  
  function toggleIcon() {
    if ($("#slide").attr("src") === "../../../../assets/images/listview/slide_up.png") {
      $("#slide").attr("src", "../../../../assets/images/listview/slide_down.png");
    } else {
      $("#slide").attr("src", "../../../../assets/images/listview/slide_up.png");
    }
  }
  
  function ToggleListAndCardView(e) {
    if ($(e).hasClass("ShowListViewjs")) {
      $(".CardViewImg").attr("src", "../../../../assets/images/listview/card-view_un-selected.png");
      $(".ListViewImg").attr("src", "../../../../assets/images/listview/list-view_selected.png");
      $(".ListViewContainer").show();
      $(".cardViewContainer").hide();
      $(".CardViewFilters").hide();
  
    }
    else if ($(e).hasClass("ShowCardViewjs")) {
      $(".CardViewImg").attr("src", "../../../../assets/images/listview/card-view_selected.png");
      $(".ListViewImg").attr("src", "../../../../assets/images/listview/list-view_un-selected.png");
      $(".cardViewContainer").show();
      $(".ListViewContainer").hide();
      $(".CardViewFilters").show();
      $(".CardViewFilters").css('display', 'inherit');
  
    }
    else {
      $(".CardViewImg").attr("src", "../../../../assets/images/listview/card-view_un-selected.png");
      $(".ListViewImg").attr("src", "../../../../assets/images/listview/list-view_selected.png");
      $(".cardViewContainer").hide();
      $(".ListViewContainer").hide();
      $(".CardViewFilters").hide();
  
    }
  }
  
  // listview function
  
  function applyListView(fnCallBack) {
  
    $(".searchbox").on("keyup", function () {
      mySearch(this.value, "searchDatajs");
    });
  
    $(".expand-icon").on("click", function () {
      toggleIcon();
      collapseOrExpandCardView();
    });
      fnCallBack();
  
      $('.ReviewDatatable').DataTable({
        bLengthChange: false,
        searching: false,
        iDisplayLength: 2
      });
      // $('.DocumentDataTable').DataTable({
      //   bLengthChange: false,
      //   searching: false,
      //   iDisplayLength: 5
      // });
  }
  function setValueToEPContactCard(a) {
    $('.headingFont').html(a);
  }
  //Filtering on card view code
  function CheckUncheckAll(chkAll, checkbox) {
    $('.' + chkAll).on('change', function () {
      $('.' + checkbox).prop('checked', $(this).prop("checked"));
    });
    //deselect "checked all", if one of the listed checkbox product is unchecked amd select "checked all" if all of the listed checkbox product is checked
    $('.' + checkbox).change(function () { //".checkbox" change
      if ($('.' + checkbox + ':checked').length == $('.' + checkbox).length) {
        $('.' + chkAll).prop('checked', true);
      } else {
        $('.' + chkAll).prop('checked', false);
      }
    });
  }
//ClientNameForotherdocFilterjs

function filterFunctionForotherdoc(filterSearchBox, DropdowntoFilter) {
    var input, filter, ul, li, a, i, txtValue;
    input = document.getElementById(filterSearchBox);
    filter = input.value.toUpperCase();
    ul = document.getElementById(DropdowntoFilter);
    li = ul.getElementsByTagName("li");
    for (i = 1; i < li.length; i++) {
      a = li[i].getElementsByTagName("a")[0];
      txtValue = a.textContent || a.innerText;
      if (a != undefined){
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          li[i].style.display = "";
        } else {
          li[i].style.display = "none";
        }
      }
    }
  }
  
  function CloseFilterotherdoc(e) {
    $(e).parents('.dropdown').find('button.dropdown-toggle').dropdown('toggle');
  }
  
  function ShowAllClientNameForotherdoc() {
    var names = $(".ClientNamejsForotherdoc").map(function () { return $(this).text(); });
  
    var distinctNames = getUniqueArrayForotherdoc(names); //$.unique(names);
    $(distinctNames).each(function (i, obj) {
      $(".ClientNameFilterjsForotherdoc").append("<li><input class='CheckClientsForotherdoc' type='checkbox' checked data-attribute='ClientNameForotherdoc' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
    });
    $(".ClientNameFilterjsForotherdoc").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterDataForotherdoc()> <input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilterReview(this)></div>");
  }
  
  function getUniqueArrayForotherdoc(array) {
    var uniqueArray = [];
  
    // Loop through array values
    for (let i = 0; i < array.length; i++) {
      if (uniqueArray.indexOf(array[i]) === -1) {
        uniqueArray.push(array[i]);
      }
    }
    return uniqueArray;
  }
  
  function ShowAllEngagementsForotherdoc() {
    var names = $(".EngagementjsForotherdoc").map(function () { return $(this).text(); });
  
    var distinctNames = getUniqueArrayReview(names); //$.unique(names);
    $(distinctNames).each(function (i, obj) {
      $(".EngagementFilterjsForotherdoc").append("<li><input class='CheckEngagementForotherdoc' type='checkbox' checked data-attribute='EngagementForotherdoc' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
    });
    $(".EngagementFilterjsForotherdoc").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterDataForotherdoc()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilteracptdoc(this)></div>");
  }
  
  function ShowAllSolutionCodeForotherdoc() {
    var names = $(".SolutionCodejsForotherdoc").map(function () { return $(this).text(); });
  
    var distinctNames = getUniqueArrayReview(names); //$.unique(names);
    $(distinctNames).each(function (i, obj) {
      $(".SolutionCodeFilterjsForotherdoc").append("<li><input class='CheckSolutionCodeForotherdoc' type='checkbox' checked data-attribute='SolutionCodeForotherdoc' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
    });
    $(".SolutionCodeFilterjsForotherdoc").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterDataForotherdoc()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilteracptdoc(this)></div>");
  }
  
  function ShowAllEPForotherdoc() {
    var names = $(".EPjsForFilterForotherdoc").map(function () { return $(this).text(); });
  
    var distinctNames = getUniqueArrayReview(names); //$.unique(names);
    $(distinctNames).each(function (i, obj) {
      $(".EPFilterjsForEngForotherdoc").append("<li role='presentation'><input class='CheckEPForotherdoc' type='checkbox' checked data-attribute='EPForotherdoc' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
    });
    $(".EPFilterjsForEngForotherdoc").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterDataForotherdoc()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilteracptdoc(this)></div>");
  }
  
  function ShowAllStatusForotherdoc() {
    var names = $(".StatusjsForFilterForotherdoc").map(function () { return $(this).text(); });
  
    var distinctNames = getUniqueArrayReview(names); //$.unique(names);
    $(distinctNames).each(function (i, obj) {
      $(".StatusFilterjsForotherdoc").append("<li><input class='CheckStatusForotherdoc' type='checkbox' checked data-attribute='StatusForotherdoc' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
    });
    $(".StatusFilterjsForotherdoc").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterDataForotherdoc()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilteracptdoc(this)></div>");
  }
  
  //Filtering on card view code
  function CheckUncheckAllForotherdoc(chkAll, checkbox) {
    $('.' + chkAll).on('change', function () {
      $('.' + checkbox).prop('checked', $(this).prop("checked"));
    });
    //deselect "checked all", if one of the listed checkbox product is unchecked amd select "checked all" if all of the listed checkbox product is checked
    $('.' + checkbox).change(function () { //".checkbox" change
      if ($('.' + checkbox + ':checked').length == $('.' + checkbox).length) {
        $('.' + chkAll).prop('checked', true);
      } else {
        $('.' + chkAll).prop('checked', false);
      }
    });
  }
  
  
  function filterTextForotherdoc(clsForFilter, attrName, obj, list) {
    //clsForFilter - ClientNameForFilter
    //attrName -  ClientName
    var result = false;
    var value = $(obj).find('.' + clsForFilter).text();
  
    var newList = $(list).filter(function (e, obj1) {
      return (obj1.attribute == ('chkAll-' + attrName)) ||
        (obj1.attribute == attrName && obj1.value == value);
    });
  
    if (newList.length > 0) {
      result = true;
  
    }
  
    return result;
  }
  
  function filterDataForotherdoc() {
    var list = [];
  
    $('.CheckClientsForotherdoc,.CheckEngagementForotherdoc,.CheckSolutionCodeForotherdoc,.CheckStatusForotherdoc,.CheckEPForotherdoc,.CheckAllClientsForotherdoc,.CheckAllEngForotherdoc,.CheckAllSolutionCodeForotherdoc,.CheckAllStatusForotherdoc,.CheckAllEPForotherdoc').each(function () {
      if ($(this).is(':checked')) {
        list.push({
          "attribute": $(this).attr("data-attribute"),
          "value": $(this).attr("data-value")
        })
      }
    });
  
    $(".FilterEngDatajsForotherdoc").filter(function () {
  
      $("#NoDataMessageForotherdoc").addClass('hide');
      var result = false;
      var $this = $(this);
      var LocAddr = $(location).attr('href').split('/');
      let routeName = LocAddr[3];
  
      if (routeName == 'engagement') {
        result = filterTextForotherdoc('ClientNameEngForotherdoc', 'ClientNameForotherdoc', $this, list);
        result = result && filterTextForotherdoc('EngagementjsForotherdoc', 'EngagementForotherdoc', $this, list);
      }
      else if (routeName == "client-summary") {
        result = filterTextForotherdoc('EngagementjsForotherdoc', 'EngagementForotherdoc', $this, list);
      }
  
      result = result && filterTextForotherdoc('SolutionCodejsForotherdoc', 'SolutionCodeForotherdoc', $this, list);
  
      result = result && filterTextForotherdoc('StatusjsForFilterForotherdoc', 'StatusForotherdoc', $this, list);
      result = result && filterTextForotherdoc('EPjsForFilterForotherdoc', 'EPForotherdoc', $this, list);
  
      if (result == false) {
        $("#NoDataMessageForotherdoc").removeClass('hide');
      }
  
      $(this).toggle(result);
        var originalFilterLength = $(".FilterEngDatajsForotherdoc").length;
        var hiddenFilterdata = $('.FilterEngDatajsForotherdoc').filter(function () {
          return $(this).css('display') == 'none';
        }).length;
  
        if (originalFilterLength == hiddenFilterdata) {
          $("#NoDataMessageForotherdoc").removeClass('hide');
        }
        else
        { $("#NoDataMessageForotherdoc").addClass('hide');}
      });
  
  }
  