
function searchData(text, classname) {
    // ".display tr"
    var value = text.toLowerCase();
    $("." + classname).filter(function () {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
    });
  }
  $(document).ready(function(){
    // $(".searchbox").on("keyup", function () {
    //   searchData(this.value, "searchReacceptanceDatajs");
    //   });
  });
  
  
//ClientNameReviewFilterjs
function filterFunctionReview(filterSearchBox, DropdowntoFilter) {
  var input, filter, ul, li, a, i, txtValue;
  input = document.getElementById(filterSearchBox);
  filter = input.value.toUpperCase();
  ul = document.getElementById(DropdowntoFilter);
  li = ul.getElementsByTagName("li");
  for (i = 1; i < li.length; i++) {
    a = li[i].getElementsByTagName("a")[0];
    txtValue = a.textContent || a.innerText;
    if (txtValue.toUpperCase().indexOf(filter) > -1) {
      li[i].style.display = "";
    } else {
      li[i].style.display = "none";
    }
  }
}
function applyListView(fnCallBack) {
  $(".searchbox").on("keyup", function () {
    mySearch(this.value, "searchReacceptanceDatajs");
  });

  $(".expand-icon").on("click", function () {
    toggleIcon();
    collapseOrExpandCardView();
  });

  fnCallBack();
}
function CloseFilterReacceptance(e) {
  $(e).parents('.dropdown').find('button.dropdown-toggle').dropdown('toggle');
}

function ShowAllClientNameReacceptance() {
  var names = $(".ClientNamejsReacceptance").map(function () { return $(this).text(); });

  var distinctNames = getUniqueArrayReacceptance(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".ClientNameFilterjsReacceptance").append("<li><input class='CheckClientReacceptance' type='checkbox' checked data-attribute='ClientName' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".ClientNameFilterjsReacceptance").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterReacceptanceData()> <input type='button' class='SecondaryButton' value='Cancel' onClick=CloseFilterReacceptance(this)></div>");
}


function ShowAllEngNameReacceptance() {
  var names = $(".EngNamejsForFilterReacceptance").map(function () { return $(this).text(); });

  var distinctNames = getUniqueArrayReacceptance(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".EngNameFilterjsReacceptance").append("<li><input class='CheckEngNameReacceptance' type='checkbox' checked data-attribute='Engagement' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".EngNameFilterjsReacceptance").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterReacceptanceData()>&nbsp;&nbsp;<input type='button' class='SecondaryButton' value='Cancel' onClick=CloseFilterReacceptance(this)></div>");
}


function ShowAllSolutionCodeReacceptance() {
  var names = $(".SolutionCodejsForFilterReacceptance").map(function () { return $(this).text(); });

  var distinctNames = getUniqueArrayReacceptance(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".SolutionCodeFilterjsReacceptance").append("<li><input class='CheckSolutionCodeReacceptance' type='checkbox' checked data-attribute='SolutionCode' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".SolutionCodeFilterjsReacceptance").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterReacceptanceData()>&nbsp;&nbsp;<input type='button' class='SecondaryButton' value='Cancel' onClick=CloseFilterReacceptance(this)></div>");
}

function ShowAllEligibilityDateReacceptance() {
  var names = $(".EligibilityDatejsForFilterReacceptance").map(function () { return $(this).text(); });

  var distinctNames = getUniqueArrayReacceptance(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".EligibilityDateFilterjsReacceptance").append("<li><input class='CheckEligibilityDateReacceptance' type='checkbox' checked data-attribute='Eligibilitydate' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".EligibilityDateFilterjsReacceptance").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterReacceptanceData()>&nbsp;&nbsp;<input type='button' class='SecondaryButton' value='Cancel' onClick=CloseFilterReacceptance(this)></div>");
}

function filterFunctionReacceptance(filterSearchBox, DropdowntoFilter) {
  var input, filter, ul, li, a, i, txtValue;
  input = document.getElementById(filterSearchBox);
  filter = input.value.toUpperCase();
  ul = document.getElementById(DropdowntoFilter);
  li = ul.getElementsByTagName("li");
  for (i = 1; i < li.length; i++) {
    a = li[i].getElementsByTagName("a")[0];
    txtValue = a.textContent || a.innerText;
    if (txtValue.toUpperCase().indexOf(filter) > -1) {
      li[i].style.display = "";
    } else {
      li[i].style.display = "none";
    }
  }
}
function getUniqueArrayReacceptance(array) {
  var uniqueArray = [];

  // Loop through array values
  for (var i = 0; i < array.length; i++) {
    if (uniqueArray.indexOf(array[i]) === -1) {
      uniqueArray.push(array[i]);
    }
  }
  return uniqueArray;
}


function filterReacceptanceData() {
  var list = [];

  $('.CheckClientReacceptance,.CheckEngNameReacceptance,.CheckSolutionCodeReacceptance,.CheckEligibilityDateReacceptance,.CheckAllClientNameReacceptance,.CheckAllEngNameReacceptance,.CheckAllSolutionCodeReacceptance,.CheckAllEligibilitydateReacceptance').each(function () {
    if ($(this).is(':checked')) {
      list.push({
        "attribute": $(this).attr("data-attribute"),
        "value": $(this).attr("data-value")
      });
    }
  });

  $(".FilterReacceptanceDatajs").filter(function () {
    var result = false;
    var $this = $(this);
    result = filterReviewText('ClientNamejsReacceptance', 'ClientName', $this, list);
    result = result && filterReviewText('EngNamejsForFilterReacceptance', 'Engagement', $this, list);
    result = result && filterReviewText('SolutionCodejsForFilterReacceptance', 'SolutionCode', $this, list);
    result = result && filterReviewText('EligibilityDatejsForFilterReacceptance', 'Eligibilitydate', $this, list);
   
    $(this).toggle(result);

    var originalFilterLength = $(".FilterReacceptanceDatajs").length;
    var hiddenFilterdata = $('.FilterReacceptanceDatajs').filter(function () {
      return $(this).addClass('hide');
    }).length;

    if (originalFilterLength == hiddenFilterdata) {
      $("#NoDataMessageReacceptance").removeClass('hide');
      $("#NoDataMessageReacceptance").html("No Data Available");
    }

  });
}

function filterReacceptanceText(clsForFilter, attrName, obj, list) {
  //clsForFilter - ClientNameForFilter
  //attrName -  ClientName
  var result = false;
  var value = $(obj).find('.' + clsForFilter).text();

  var newList = $(list).filter(function (e, object) {
    return (object.attribute == ('chkAll-' + attrName)) ||
      (object.attribute == attrName && object.value == value);
  });

  if (newList.length > 0) {
    result = true;

  }
  return result;
}

//Filtering on card view code
function CheckUncheckAllReacceptance(chkAll, checkbox) {
  $('.' + chkAll).on('change', function () {
    $('.' + checkbox).prop('checked', $(this).prop("checked"));
  });
  //deselect "checked all", if one of the listed checkbox product is unchecked amd select "checked all" if all of the listed checkbox product is checked
  $('.' + checkbox).change(function () { //".checkbox" change 
    if ($('.' + checkbox + ':checked').length == $('.' + checkbox).length) {
      $('.' + chkAll).prop('checked', true);
    } else {
      $('.' + chkAll).prop('checked', false);
    }
  });
}