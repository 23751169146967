function mySearch(text, classname) {
  // ".display tr"
  var value = text.toLowerCase();
  $("." + classname).filter(function () {
    $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
  });
}

function toggleIcon() {
  if ($("#slide").attr("src") === "../../../../assets/images/listview/slide_up.png") {
    $("#slide").attr("src", "../../../../assets/images/listview/slide_down.png");
  } else {
    $("#slide").attr("src", "../../../../assets/images/listview/slide_up.png");
  }
}

function ToggleListAndCardView(e) {
  if ($(e).hasClass("ShowListViewjs")) {
    $(".CardViewImg").attr("src", "../../../../assets/images/listview/card-view_un-selected.png");
    $(".ListViewImg").attr("src", "../../../../assets/images/listview/list-view_selected.png");
    $(".ListViewContainer").show();
    $(".cardViewContainer").hide();
    $(".CardViewFilters").hide();

  }
  else if ($(e).hasClass("ShowCardViewjs")) {
    $(".CardViewImg").attr("src", "../../../../assets/images/listview/card-view_selected.png");
    $(".ListViewImg").attr("src", "../../../../assets/images/listview/list-view_un-selected.png");
    $(".cardViewContainer").show();
    $(".ListViewContainer").hide();
    $(".CardViewFilters").show();
    $(".CardViewFilters").css('display', 'inherit');

  }
  else {
    $(".CardViewImg").attr("src", "../../../../assets/images/listview/card-view_un-selected.png");
    $(".ListViewImg").attr("src", "../../../../assets/images/listview/list-view_selected.png");
    $(".cardViewContainer").hide();
    $(".ListViewContainer").hide();
    $(".CardViewFilters").hide();

  }
}

// listview function

function applyListView(fnCallBack) {

  $(".searchbox").on("keyup", function () {
    mySearch(this.value, "searchDatajs");
  });

  $(".expand-icon").on("click", function () {
    toggleIcon();
    collapseOrExpandCardView();
  });
    fnCallBack();

    $('.ReviewDatatable').DataTable({
      bLengthChange: false,
      searching: false,
      iDisplayLength: 2
    });
    // $('.DocumentDataTable').DataTable({
    //   bLengthChange: false,
    //   searching: false,
    //   iDisplayLength: 5
    // });
}
function setValueToEPContactCard(a) {
  $('.headingFont').html(a);
}
//Filtering on card view code
function CheckUncheckAll(chkAll, checkbox) {
  $('.' + chkAll).on('change', function () {
    $('.' + checkbox).prop('checked', $(this).prop("checked"));
  });
  //deselect "checked all", if one of the listed checkbox product is unchecked amd select "checked all" if all of the listed checkbox product is checked
  $('.' + checkbox).change(function () { //".checkbox" change
    if ($('.' + checkbox + ':checked').length == $('.' + checkbox).length) {
      $('.' + chkAll).prop('checked', true);
    } else {
      $('.' + chkAll).prop('checked', false);
    }
  });
}


function ShowAllClientName() {
  var names = $(".ClientNamejsForEng").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".ClientNameFilterjsForEng").append("<li><input class='CheckClientForEng' type='checkbox' checked data-attribute='ClientName' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".ClientNameFilterjsForEng").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterEngData()> <input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}


function ShowAllEngagements() {
  var names = $(".EngagementjsForFilter").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".EngagementFilterjsForEng").append("<li><input class='CheckEngagement' type='checkbox' checked data-attribute='Engagement' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".EngagementFilterjsForEng").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterEngData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}


function ShowAllSolutionCode() {
  var names = $(".SolutionCodejsForFilter").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".SolutionCodeFilterjsForEng").append("<li><input class='CheckSolutionCode' type='checkbox' checked data-attribute='SolutionCode' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".SolutionCodeFilterjsForEng").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterEngData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}

function ShowAllStatus() {
  var names = $(".StatusjsForFilter").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".StatusFilterjsForEng").append("<li><input class='CheckStatus' type='checkbox' checked data-attribute='Status' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".StatusFilterjsForEng").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterEngData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}


function ShowAllEP() {
  var names = $(".EPjsForFilter").map(function () { return $(this).text(); });

  var distinctNames = getUnique(names); //$.unique(names);
  $(distinctNames).each(function (i, obj) {
    $(".EPFilterjsForEng").append("<li role='presentation'><input class='CheckEP' type='checkbox' checked data-attribute='EP' data-value='" + obj + "'/>&nbsp;<a>" + obj + "</a></li>");
  });
  $(".EPFilterjsForEng").append("<hr/><div  class='float-right filterbuttonDiv'><input type='button' class='PrimaryButton' value='Apply' onClick=filterEngData()>&nbsp;&nbsp;<input type='button' class='btn SecondaryButton' value='Cancel' onClick=CloseFilter(this)></div>");
}

function getUnique(array) {
  var uniqueArray = [];

  // Loop through array values
  for (let i = 0; i < array.length; i++) {
    if (uniqueArray.indexOf(array[i]) === -1) {
      uniqueArray.push(array[i]);
    }
  }
  return uniqueArray;
}

//ClientNameFilterjs
function filterFunction(filterSearchBox, DropdowntoFilter) {
  var input, filter, ul, li, a, i, txtValue;
  input = document.getElementById(filterSearchBox);
  filter = input.value.toUpperCase();
  ul = document.getElementById(DropdowntoFilter);
  li = ul.getElementsByTagName("li");
  for (i = 1; i < li.length; i++) {
    a = li[i].getElementsByTagName("a")[0];
    if (a != undefined){
      txtValue = a.textContent || a.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
  }
  }
}


function filterEngData() {
  var list = [];

  $('.CheckClientForEng,.CheckEngagement,.CheckSolutionCode,.CheckStatus,.CheckEP,.CheckAllClientsEng,.CheckAllEng,.CheckAllSolutionCode,.CheckAllStatus,.CheckAllEP').each(function () {
    if ($(this).is(':checked')) {
      list.push({
        "attribute": $(this).attr("data-attribute"),
        "value": $(this).attr("data-value")
      })
    }
  });

  $(".FilterEngDatajs").filter(function () {
    $("#NoDataMessage").addClass('hide');
    var result = false;
    var $this = $(this);
    var LocAddr = $(location).attr('href').split('/');
    let routeName = LocAddr[3];
    if (routeName == 'engagement') {
      result = filterText('ClientNameForFilterEng', 'ClientName', $this, list);
      result = result && filterText('EngagementjsForFilter', 'Engagement', $this, list);
    }
    else if (routeName == "client-summary") {
      result = filterText('EngagementjsForFilter', 'Engagement', $this, list);
    }

    result = result && filterText('SolutionCodejsForFilter', 'SolutionCode', $this, list);

    result = result && filterText('StatusjsForFilter', 'Status', $this, list);
    result = result && filterText('EPjsForFilter', 'EP', $this, list);

    if (result == false) {
      $("#NoDataMessage").removeClass('hide');
    }

    $(this).toggle(result);
    var originalFilterLength = $(".FilterEngDatajs").length;
      var hiddenFilterdata = $('.FilterEngDatajs').filter(function () {
        return $(this).css('display') == 'none';
      }).length;

      if (originalFilterLength == hiddenFilterdata) {
        $("#NoDataMessage").removeClass('hide');
      }
      else
      { $("#NoDataMessage").addClass('hide');}
    });

}

function filterText(clsForFilter, attrName, obj, list) {
  //clsForFilter - ClientNameForFilter
  //attrName -  ClientName
  var result = false;
  var value = $(obj).find('.' + clsForFilter).text();

  var newList = $(list).filter(function (e, eachObj) {
    return (eachObj.attribute == ('chkAll-' + attrName)) ||
      (eachObj.attribute == attrName && eachObj.value == value);
  });

  if (newList.length > 0) {
    result = true;
  }

  return result;
}